<!--suppress Stylelint -->
<script>
import Layout from '@layouts/main.vue'
import NosPartenaires from '@components/nos-partenaires-component.vue'

import { projetDoneComputed } from '@state/helpers'
export default {
  page: {
    title: '',
  },
  components: { Layout, NosPartenaires },

  computed: {
    ...projetDoneComputed,
  },

  data() {
    return {
      color: 'red',
      font: {
        weight: '800',
      },
    }
  },

  created() {
    // look up the project
    this.solution = this.projetDone.find(
      (project) => project.path === this.$route.params.projetDone
    )

    // if it exists, proceed
    if (!this.solution) {
      this.$router.push('/404')
    }
  },
}
</script>

<template>
  <Layout class="project">
    <section
      :style="{
        backgroundImage: 'url(' + require('@assets/images/logo-bg.svg') + ')',
      }"
      :class="$style.welcomeSection"
      class="block block--hpbanner"
    >
      <div class="container">
        <div
          :class="$style.article"
          class="d-flex flex-column justify-content-center"
        >
          <div :class="$style.title">
            <p :style="'color:' + solution.hexaColor" class="h4 text-uppercase"
              >Solution</p
            >
            <h1 class="large-header">{{ solution.name }}</h1>
            <div :class="$style.content">
              <p v-html="solution.info"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="row text-center justify-content-center">
          <div class="col-11">
            <p class="display-4">Avantages</p>
            <p class="font-weight-light">{{ solution.avantage }}</p>
          </div>
        </div>

        <div v-if="solution" class="row mt-5 pt-5">
          <div
            v-for="(item, index) in solution.avantages"
            :key="item.name"
            class="col-sm-12 col-md-4 col-lg-4 col-xl-4 my-4"
          >
            <div
              :class="$style.card"
              class="card bg-dark text-white rounded-0 h-100 border-top-0 border-left-0 border-right-0"
              :style="
                '  border-bottom: 3px solid ' +
                solution.hexaColor +
                '!important;'
              "
            >
              <div
                class="card-body d-flex justify-content-between align-items-start flex-column"
              >
                <header>
                  <i
                    v-if="index === 0"
                    class="ico-organise"
                    :style="'color:' + solution.hexaColor"
                  ></i>
                  <i
                    v-if="index === 1"
                    class="ico-protege"
                    :style="'color:' + solution.hexaColor"
                  ></i>
                  <i
                    v-if="index === 2"
                    class="ico-connecte"
                    :style="'color:' + solution.hexaColor"
                  ></i>
                  <p class="h4 font-weight-bold">{{ item.name }}</p>
                  <hr :class="'bg-color-' + solution.color" />
                  <p class="mt-4">{{ item.description }}</p>
                </header>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="padder-50 mb-5 mt-5">
      <div :class="$style.sectionContainer">
        <div
          :class="$style.bg"
          :style="{
            backgroundImage:
              'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 95%, rgba(0,0,0,1) 100%), url(' +
              require('@assets/images/logo-bg.svg') +
              ')',
          }"
        ></div>
        <div
          :class="$style.bgSolution"
          :style="'background-color:' + solution.hexaColor"
        ></div>
        <div class="container position-relative">
          <div class="row">
            <div :class="$style.nousSommesBlock" class="col-lg-6">
              <div :class="$style.nousSommesBlockPadding">
                <!-- <h3 class="font-weight-bolder text-uppercase title-section"
              >Notre mission</h3 -->
                <h2 class="font-weight-bolder title-section-desc"
                  >Détails<br />de la Solution</h2
                >
              </div>
            </div>
            <div class="col-lg-6">
              <div :class="$style.solutionContent">
                <p class="text-justify" v-html="solution.details"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container pt-5 text-center">
        <a class="btn" href="/contact">
          <span :class="'circle bg-color-' + solution.color"> </span>
          <span class="button-text">Demander une démo</span>
        </a>
      </div>
    </section>

    <section :class="$style.functionality">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-xl-6 d-flex align-items-center order-lg-2">
            <div
              class="d-flex justify-content-center align-items-start flex-column"
              :class="$style.functionalityContent"
            >
              <p class="display-4">Fonctionnalités</p>
              <p class="small">{{ solution.fonctionnalites }}</p>
            </div>
          </div>
          <div class="col-lg-6 col-xl-6 order-lg-1">
            <div class="container-fluid">
              <div class="row">
                <div class="col text-center mb-3" :class="$style.box">
                  <i
                    class="ico-search"
                    :style="'color:' + solution.hexaColor"
                  ></i>
                  <p class="lead">{{ solution.fonctionnalitesDesc[0].name }}</p>
                  <p class="small">{{
                    solution.fonctionnalitesDesc[0].description
                  }}</p>
                </div>
                <div class="col text-center" :class="$style.box">
                  <i class="ico-win" :style="'color:' + solution.hexaColor"></i>

                  <p class="lead">{{ solution.fonctionnalitesDesc[1].name }}</p>
                  <p class="small">{{
                    solution.fonctionnalitesDesc[1].description
                  }}</p>
                </div>
                <div class="w-100"></div>
                <div class="col text-center" :class="$style.box">
                  <i
                    class="ico-suitcase"
                    :style="'color:' + solution.hexaColor"
                  ></i>
                  <p class="lead">{{ solution.fonctionnalitesDesc[2].name }}</p>
                  <p class="small">{{
                    solution.fonctionnalitesDesc[2].description
                  }}</p>
                </div>
                <div class="col text-center" :class="$style.box">
                  <i
                    class="ico-medal"
                    :style="'color:' + solution.hexaColor"
                  ></i>
                  <p class="lead">{{ solution.fonctionnalitesDesc[3].name }}</p>
                  <p class="small">{{
                    solution.fonctionnalitesDesc[3].description
                  }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <NosPartenaires></NosPartenaires>
  </Layout>
</template>

<style lang="scss" module>
@import '@design';

.text {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.welcomeSection {
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
}

.purple {
  color: #761d75;
  border-color: #761d75;
}

.article {
  height: 100vh;
  min-height: 600px;
  .content {
    p {
      max-width: 30em;
    }
  }
}
header {
  [class^='ico-'] {
    font-size: 68px;
  }
}

.card {
  background-color: #111 !important;
  padding: 15px 15px 60px 15px;
  @include respond-below(sm) {
    padding: 15px;
  }
}

.sectionContainer {
  position: relative;
  .bgSolution {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    @include respond-below(md) {
      width: 100%;
      height: 180px;
    }
  }
  .bg {
    position: absolute;
    top: -50%;
    right: 0;
    z-index: -1;
    width: 50%;
    height: 200%;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
  }
}

.solutionContent {
  padding: 3rem 0;
  p {
    margin-bottom: 0;
  }
}
.nousSommesBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .nousSommesBlockPadding {
    padding: 3rem 0;
    h2 {
      padding-left: 40px;
    }
  }
}

.box {
  img {
    height: 64px;
    width: 64px;
    stroke: #761d75;
    margin-bottom: 20px;
    filter: invert(13%) sepia(37%) saturate(6187%) hue-rotate(288deg)
      brightness(92%) contrast(90%);
  }
  p {
    text-align: center;
    line-height: 17px;
  }
  [class^='ico-'] {
    font-size: 80px;
    margin-bottom: 20px;
    display: block;
  }
}
.functionality {
  .functionalityContent {
    @include respond-below(md) {
      display: block !important;
      text-align: center;
      width: 100%;
      margin-bottom: 50px;
    }
  }
}
</style>
